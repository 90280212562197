import React from "react";

import "./style.scss";

interface IDesignerAdvantages {
  containerRef: React.RefObject<HTMLDivElement>;
}

const DesignerAdvantages: React.FC<IDesignerAdvantages> = ({
  containerRef,
}) => {
  return (
    <div className="worker-advantages__container" ref={containerRef}>
      <div className="customer__title">ДИЗАЙНЕРАМ</div>
      <div className="customer__subtitle">Преимущества</div>
      <div className="worker-advantages__img-wrapper">
        <div className="customer-advantages__img designer-advantages__img" />
      </div>
    </div>
  );
};

export default DesignerAdvantages;
