import React from 'react';

import classnames from 'classnames';

import CheckedIcon from './CheckedIcon';
import EmptyIcon from './EmptyIcon';

import './style.scss';

interface IRadioProps {
  checked: boolean;
  value: string;
  setValue(value: string): void;
  children?: React.ReactNode;
  label?: string;
  error?: boolean;
  disabled?: boolean;
}

const Radio: React.FC<IRadioProps> = ({
  checked,
  value,
  setValue,
  error,
  disabled,
  label,
  children,
}) => {
  const toggleValue = () => {
    if (!checked) setValue(value);
  };
  return (
    <button
      className={classnames('radio-row')}
      onClick={toggleValue}
      disabled={disabled}
    >
      {checked ? (
        <CheckedIcon className="checked-icon" />
      ) : (
        <EmptyIcon className={classnames('empty-icon', { error })} />
      )}
      <div className="radio-content">
        {label && <span className="radio-label">{label}</span>}
        {children}
      </div>
    </button>
  );
};

export default Radio;
