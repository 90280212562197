import React from "react";

import TelegramIcon from "../../icons/TelegramIcon";
import VkIcon from "../../icons/VkIcon";
import Divider from "../Divider/Divider";

const Footer = () => {
  return (
    <div className="footer__container">
      <div className="info-page__container">
        <Divider className="divider__horizontal"></Divider>
        <div className="footer__content">
          <div className="footer__links-wrapper">
            <div className="footer__links-column">
              <p className="footer__link-title">О сервисе</p>
              <p className="footer__link-subtitle">Для заказчика</p>
              <p className="footer__link-subtitle">Для прораба</p>
            </div>
            <div className="footer__links-column">
              <p className="footer__link-title">Помощь</p>
              <p className="footer__link-subtitle">Служба поддержки</p>
              <p className="footer__link-subtitle">Контакты</p>
            </div>
            <div className="footer__links-column">
              <p className="footer__link-title">Информация</p>
              <a
                className="footer__link-subtitle"
                href="/Политика_обработки_персональных_данных.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Политика обработки персональных данных
              </a>
              <a
                className="footer__link-subtitle"
                href="/Согласие_на_обработку_персональных_данных.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Согласие на обработку персональных данных
              </a>
              <p className="footer__link-subtitle">Оферта</p>
            </div>
            <div className="footer__links-column">
              <p className="footer__link-title">Сообщество</p>
              <div className="footer__link-social">
                <div className="footer__link-icon">
                  <TelegramIcon />
                </div>
                <a
                  href="https://t.me/remontprostoonline"
                  className="footer__subtitle-text"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Telegram
                </a>
              </div>
              <div className="footer__link-social">
                <div className="footer__link-icon">
                  <VkIcon />
                </div>
                <a
                  href="https://vk.com/remontprostoonline"
                  className="footer__subtitle-text"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ВКонтакте
                </a>
              </div>
            </div>
          </div>
          <div className="footer__line"></div>
          <div className="footer__logo-wrapper">
            <p className="footer__logo-year">2022</p>
            <p className="footer__logo-copyright">©</p>
            <p className="footer__logo-text">Ремонт. Просто</p>
          </div>
        </div>
        <Divider className="divider__horizontal"></Divider>
      </div>
    </div>
  );
};

export default Footer;
