import React, { FC } from 'react';

interface LabelProps {
  value: string;
  required?: boolean;
}

const Label: FC<LabelProps> = ({ value, required }) => {
  return (
    <div className="label__container">
      <h5 className="label__h5">
        {value}
        {required && <p className="laber__required">*</p>}
      </h5>
    </div>
  );
};

export default Label;
