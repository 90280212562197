import * as React from "react";

function ArrowIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={8}
      height={12}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 0L.59 1.41 5.17 6 .59 10.59 2 12l6-6-6-6z"
        fill="#686ACA"
      />
    </svg>
  );
}

export default ArrowIcon;
