import React from "react";

import Button from "../../../../components/Buttons/Button/Button";

import "./style.scss";

interface IIntro {
  containerRef: React.RefObject<HTMLDivElement>;
  showContactForm(): void;
}

const Intro: React.FC<IIntro> = ({ containerRef, showContactForm }) => {
  return (
    <div className="info__intro-container" ref={containerRef}>
      <div className="info__intro-left-col">
        <span className="info__intro-title">РЕМОНТ.ПРОСТО</span>
        <span className="info__intro-subtitle">
          Помогаем сделать ремонт простым и&nbsp;понятным, прозрачным
          и&nbsp;контролируемым
        </span>
        <span className="info__intro-desc">
          Новая экосистема ремонта объединяет в&nbsp;едином пространстве
          ключевых участников процесса: заказчиков, прорабов, дизайнеров,
          поставщиком материалов, банки и&nbsp;страховые организации.
        </span>
        <div className="info__intro-btn">
          <Button onClick={showContactForm}>Оставить контакты</Button>
        </div>
      </div>
      <div className="info__intro-img" />
    </div>
  );
};

export default Intro;
