import React, { ComponentProps, FC } from 'react';

import classnames from 'classnames';

import './buttonStyle.scss';

interface ButtonProps extends ComponentProps<"button"> {
  mode?: "main-button" | "secondary-button";
  size?: "SMALL" | "MEDIUM" | "LARGE";
}

const Button: FC<ButtonProps> = ({
  mode = "main-button",
  size = "LARGE",
  ...props
}) => {
  return (
    <button
      {...props}
      className={classnames("button", mode, size?.toLowerCase())}
    >
      {props.children}
    </button>
  );
};

export default Button;
