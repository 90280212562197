import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Checkbox from '../../components/Checkbox';
import Divider from '../../components/Divider/Divider';
import SideMenu from '../../components/SideMenu/SideMenu';
import AvatarIcon from '../../icons/AvatarIcon';
import EditIcon from '../../icons/EditIcon';
import { selectUser } from '../../store/projectSlice';

interface IProfileCustomerViewProps {
  setEditMode(): void;
}

const ProfileCustomerView: React.FC<IProfileCustomerViewProps> = ({
  setEditMode,
}) => {
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const [telegramNotifications, setTelegramNotifications] =
    React.useState<boolean>(false);
  const [emailNotifications, setEmailNotifications] =
    React.useState<boolean>(false);

  const goTo = (path: string, blank?: boolean) => {
    if (!blank) {
      navigate(path);
      return;
    }
    window.open(path, '_blank')?.focus();
  };
  return (
    <>
      <div className="profile__container">
        <div className="profile__content">
          <SideMenu />
          {user && (
            <div className="profile__right-wrapper-large">
              <div className="profile__right-content view">
                <div className="profile__right-title-flex">
                  <h1>Профиль</h1>
                  <div className="profile__edit">
                    <button onClick={setEditMode}>
                      <EditIcon />
                    </button>
                  </div>
                </div>
                <div className="profile__userinfo">
                  <div className="profile__avatar">
                    <AvatarIcon />
                  </div>
                  <div className="profile__about">
                    {user?.name && (
                      <h4>
                        {`${user.name}`}
                        {user?.patronymic && ` ${user?.patronymic}`}
                        {user?.surname && ` ${user?.surname}`}
                      </h4>
                    )}
                    <div className="profile__about-email">{user?.email}</div>
                  </div>
                </div>
                {user?.phone && (
                  <div className="profile__info">
                    {user?.phone && (
                      <div className="profile__info-section mb-24">
                        <h5 className="mb-6">Телефон:</h5>
                        <div className="body__16">{user.phone}</div>
                      </div>
                    )}
                  </div>
                )}
                <div className="profile__notification">
                  <h5 className="profile__notification-title">
                    Получать уведомления
                  </h5>
                  <div className="profile__notification-block mb-60">
                    <div className="profile__checkboxes">
                      <Checkbox
                        label="Telegram"
                        checkedValue={telegramNotifications}
                        setCheckedValue={setTelegramNotifications}
                        disabled={!user?.phone}
                      />
                      <Checkbox
                        label="Email"
                        checkedValue={emailNotifications}
                        setCheckedValue={setEmailNotifications}
                      />
                    </div>
                    {!user?.phone && (
                      <div className="profile__phonelink">
                        <a className="body__link" href="/">
                          Указать телефон
                        </a>
                      </div>
                    )}
                  </div>
                </div>
                {/* <div className="mb-120">
                <MainButton
                  onClick={() => goTo("#")}
                  width="288px"
                  children="Сохранить"
                />
              </div> */}
              </div>
              <Divider className="divider__horizontal" />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ProfileCustomerView;
