import React, { FC, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Button from '../../components/Buttons/Button/Button';
import Divider from '../../components/Divider/Divider';
import Dropdown from '../../components/Dropdown/Dropdown';
import Label from '../../components/Label/Label';
import PageLayout from '../../components/PageLayout/PageLayout';
import appDataStorage from '../../functions/appDataStorage';
import {components} from "../../generated/apiTypes";
import HouseIcon from '../../icons/HouseIcon';
import WorkerIcon from '../../icons/WorkerIcon';
import Instance from '../../settings/AxiosInstance';
import { RootState } from '../../store/configureStore';
import { setUser } from '../../store/projectSlice';
import { IProjectSlice } from '../../store/types';

const instance = new Instance();

interface RegistrationRoleStatusSelectProps {
  user: IProjectSlice['user'];
}

const RegistrationRoleStatusSelect: FC<RegistrationRoleStatusSelectProps> = ({
  user,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [role, setRole] = useState<string>('');
  const [status, setStatus] = useState<string>('');

  const [validationErrors, setValidationErrors] = useState<any>({
    roleError: false,
    role: '',
    statusError: false,
    status: '',
  });

  const goTo = (path: string, blank?: boolean) => {
    if (!blank) {
      navigate(path);
      return;
    }
    window.open(path, '_blank')?.focus();
  };

  const fetchRegistrationData = async () => {
    try {
      const response = await instance.post('/registration/worker', {
        email: user?.email,
        role: role,
        status: status,
      });

      if (response && response.status === 201) {
        if (response.data.authorizeService) {
          appDataStorage().setData('token', response.data.session.accessToken);
          dispatch(setUser(response.data));
          if ((response.data as components['schemas']['User'])?.role === 'USER')
            goTo('/profile');
        } else {
          goTo('/registration/confirm');
        }
      }

      if (response && response.status === 422) {
        const newObj: any = { ...validationErrors };
        response.data.errors.forEach((item: any) => {
          newObj[item.context.label] = item.message;
        });
        setValidationErrors(newObj);
      }

      if (response && response.status === 400) {
        const newObj: any = { ...validationErrors };
        newObj.role = true;
        newObj.status = response.data.message;
        setValidationErrors(newObj);
      }
    } catch (e) {
      console.log('E', e);
    }
  };

  return (
    <PageLayout>
      <Divider height="70px" />
      <div className="registration__select__container">
        <div className="registration__select__content">
          <div className="registration__select-card">
            <div className="registration__select-card-title mb-18">
              <WorkerIcon />
              <h2 className="ml-26">Предложить услуги</h2>
            </div>
            <div className="registration__select-card-note mb-42">
              <h5>если вы ищете заказы </h5>
            </div>
            <div className="registration__select-card-dropdown mb-30">
              <Label value="Роль" required />
              <Dropdown
                placeholder="Укажите свою роль"
                selectedValue={role}
                list={[
                  { key: 'DESIGNER', value: 'Дизайнер' },
                  { key: 'FOREMAN', value: 'Прораб' },
                ]}
                handleValue={setRole}
              />
            </div>
            <div className="registration__select-card-dropdown mb-42">
              <Label value="Статус" required />
              <Dropdown
                placeholder="Выберите статус"
                selectedValue={status}
                list={[
                  { key: 'PRIVATE', value: 'Частное лицо' },
                  { key: 'ENTREPRENEUR', value: 'ИП' },
                  { key: 'FIRM', value: 'Юридическое лицо' },
                ]}
                handleValue={setStatus}
              />
            </div>
            <div className="registration__select-card-button">
              <Button onClick={fetchRegistrationData}>
                <p>Продолжить</p>
              </Button>
            </div>
          </div>
          {/* <div className="registration__select-card">
            <div className="registration__select-card-title mb-18">
              <HouseIcon />
              <h2 className="ml-26">Заказать ремонт</h2>
            </div>
            <div className="registration__select-card-note mb-42">
              <h5>если вы ищете исполнителя работ</h5>
            </div>
            <div className="registration__select-card-button">
              <Button
                mode="secondary-button"
                onClick={() => goTo('/registration/complete')}
              >
                <p>Хочу заказать ремонт</p>
              </Button>
            </div>
          </div> */}
        </div>
      </div>
    </PageLayout>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: state.project.user,
});

export default connect(mapStateToProps)(RegistrationRoleStatusSelect);
