import React from "react";

import Button from "../../../../components/Buttons/Button/Button";

import "./style.scss";

interface ICustomerOptionsProps {
  showContactForm(): void;
}

const CustomerOptions: React.FC<ICustomerOptionsProps> = ({
  showContactForm,
}) => {
  return (
    <div className="customer-options__container">
      <div className="info-page__container">
        <span className="customer__subtitle">Возможности</span>
        <div className="customer-options__content">
          <div className="customer-options__content-column">
            <div className="info__options-block">
              <span className="info__options-number">01</span>
              <span className="info__options-subtitle">
                Находите и&nbsp;выбирайте проверенных исполнителей
              </span>
              <span className="info__options-desc">
                Выбирайте подходящих исполнителей по&nbsp;чётким критериям: опыт
                работ, портфолио, образование, отзывы, цены, удалённость
                от&nbsp;объекта.
              </span>
            </div>
            <div className="info__options-block">
              <span className="info__options-number">02</span>
              <span className="info__options-subtitle">
                Контролируйте весь процесс ремонта в&nbsp;одном месте
              </span>
              <span className="info__options-desc">
                Оперативно оценивайте ключевые показатели по&nbsp;ремонту:
                время, стоимость, готовность работ и&nbsp;закупки материалов.
              </span>
            </div>
            <div className="info__options-block">
              <span className="info__options-number">03</span>
              <span className="info__options-subtitle">
                Подробная смета ремонта с&nbsp;подсказками
              </span>
              <span className="info__options-desc">
                Просматривайте подробную смету по&nbsp;вашему ремонту
                в&nbsp;сервисе Ремонт.Просто. Мы автоматически подсветим вам,
                какие работы и&nbsp;материалы могут являться лишними.
              </span>
            </div>
            <div className="info__options-img img1" />
          </div>
          <div className="customer-options__content-column">
            <div className="customer-options__content-column-top">
              <div className="info__options-block">
                <div className="info__options-img img2" />
              </div>
              <div className="info__options-block">
                <span className="info__options-number">04</span>
                <span className="info__options-subtitle">
                  Автоматический тендер по&nbsp;материалам
                </span>
                <span className="info__options-desc">
                  Все цены основных строительных магазинов в&nbsp;одном месте.
                  Ремонт.Просто подскажет, в&nbsp;каком магазине сейчас выгоднее
                  купить нужные материалы.
                </span>
              </div>
              <div className="info__options-block">
                <span className="info__options-number">05</span>
                <span className="info__options-subtitle">
                  Документы, чеки, переписка в&nbsp;одном месте
                </span>
                <span className="info__options-desc">
                  Загружайте необходимые документы по&nbsp;работам, сохраняйте
                  чеки, контролируйте историю выплат, храните историю общения
                  с&nbsp;исполнителем.
                </span>
              </div>
            </div>
            <div className="info__contacts-btn">
              <Button onClick={showContactForm}>Оставить контакты</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerOptions;
