import * as React from "react";

function HelpIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.5 3h-15A1.5 1.5 0 003 4.5v15A1.5 1.5 0 004.5 21h15a1.5 1.5 0 001.5-1.5v-15A1.5 1.5 0 0019.5 3z"
        stroke="#5E5D67"
        strokeWidth={2}
        strokeLinejoin="round"
      />
      <path
        d="M12 14.313v-2a3 3 0 10-3-3"
        stroke="#5E5D67"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 18.813a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5z"
        fill="#5E5D67"
      />
    </svg>
  );
}

export default HelpIcon;
