import React from 'react';

import Button from '../../../../components/Buttons/Button/Button';
import LogoIcon from '../../../../icons/LogoIcon';
import { InfoBlock } from '../../info';

import "./style.scss";

interface IHeader {
  scrollToBlock(block: InfoBlock): void;
  showContactForm(): void;
}

const Header: React.FC<IHeader> = ({ scrollToBlock, showContactForm }) => {
  return (
    <div className="info__header-wrapper">
      <div className="info-page__container">
        <div className="info__header-container">
          <div className="header__logo">
            <div className="header__logo-icon">
              <LogoIcon />
            </div>
            <div className="header__logo-title">Ремонт.Просто</div>
          </div>
          <div className="info__header-links">
            <button
              className="info__header-link"
              onClick={() => scrollToBlock("CUSTOMER")}
            >
              Заказчикам
            </button>
            <button
              className="info__header-link"
              onClick={() => scrollToBlock("WORKER")}
            >
              Прорабам
            </button>
            <button
              className="info__header-link"
              onClick={() => scrollToBlock("DESIGNER")}
            >
              Дизайнерам
            </button>
            <button
              className="info__header-link"
              onClick={() => scrollToBlock("PARTNERS")}
            >
              Партнерам
            </button>
          </div>
          <div className="info__header-contacts-btn">
            <Button
              mode="secondary-button"
              size="MEDIUM"
              onClick={showContactForm}
            >
              Оставить контакты
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
