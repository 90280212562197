import * as React from "react";

function CommentIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.5 19H11v-4h7v-4h4v8h-2.5L18 20.5 16.5 19z"
        stroke="#5E5D67"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 3h16v12H8.5l-2 2-2-2H2V3z"
        stroke="#5E5D67"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 11h3M6 7h6"
        stroke="#5E5D67"
        strokeWidth={2}
        strokeLinecap="round"
      />
    </svg>
  );
}

export default CommentIcon;
