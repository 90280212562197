import React from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '../../components/Buttons/Button/Button';
import Divider from '../../components/Divider/Divider';
import PageLayout from '../../components/PageLayout/PageLayout';
import EyeOpenIcon from '../../icons/EyeOpenIcon';

const Recover = () => {
  const navigate = useNavigate();

  const goTo = (path: string, blank?: boolean) => {
    if (!blank) {
      navigate(path);
      return;
    }
    window.open(path, '_blank')?.focus();
  };
  return (
    <PageLayout>
      <div className="recover__container">
        <Divider className="divider__horizontal"></Divider>
        <div className="recover__content">
          <div className="form__header">
            <h1>Новый пароль</h1>
          </div>
          <div className="form">
            <div className="form__input">
              <label htmlFor="email">
                <h5 className="mb-4">Email</h5>
              </label>
              <input id="email" className="form__input-item mb-30" type="email" disabled />
            </div>
            <div className="form">
              <div className="form__input">
                <label htmlFor="password">
                  <h5 className="mb-4">
                    Новый пароль<span className="dot">*</span>
                  </h5>
                </label>
                <input id="password" className="form__input-item" type="password" />
                <span className="eye-icon-low">
                  <EyeOpenIcon />
                </span>
              </div>
            </div>
            <span className="body__tag mb-48">
              Не менее 6 знаков: латинские буквы, цифры, специальные символы
            </span>

            <Button onClick={() => goTo('/')} children="Сохранить" />
          </div>
        </div>
        <Divider className="divider__horizontal"></Divider>
      </div>
    </PageLayout>
  );
};

export default Recover;
