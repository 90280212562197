import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';

import classNames from "classnames";

import useOutsideClickHandler from '../../functions/outsideClickHandler';
import ArrowDownIcon from '../../icons/ArrowDownIcon';
import ArrowUpIcon from '../../icons/ArrowUpIcon';
import CheckmarkIcon from '../../icons/CheckmarkIcon';

interface DropdownListProps {
  key: string;
  value: string;
}

interface DropdownProps {
  placeholder?: string;
  list: DropdownListProps[];
  selectedValue?: string;
  handleValue: (e: string) => void;
}

const Dropdown: FC<DropdownProps> = ({
  placeholder,
  list,
  selectedValue,
  handleValue,
}) => {
  const wrapperRef = useRef<any>(null);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleClose = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (key: string) => {
    handleClose();
    handleValue(key);
  };

  const prepareSelectedValue = () => {
    let str: any;
    list.forEach(item => {
      if (item.key === selectedValue) {
        str = item.value;
      }
    });
    return str;
  };

  // useOutsideClickHandler(wrapperRef, isOpen, handleClose);

  const isSelected = (key: string) => {
    return selectedValue === key;

  };

  function handleClickOutside(event) {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="dropdown__container" ref={wrapperRef}>
      <button className="dropdown__button large" onClick={handleClose}>
        {selectedValue ? (
          <p className="body__text-16">{prepareSelectedValue()}</p>
        ) : placeholder ? (
          <p className="body__text-16 dropdown__placeholder">{placeholder}</p>
        ) : (
          <p></p>
        )}
        {isOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
      </button>
      {isOpen && (
        <div className="dropdown__list">
          {list.map((item, i) => (
            <button
              key={i}
              className={classNames(
                'dropdown__list-button',
                isSelected(item.key) && 'selected',
              )}
              onClick={() => handleSelect(item.key)}
            >
              <p className="body__text-16">{item.value}</p>
              {isSelected(item.key) && <CheckmarkIcon />}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
