import React, { useEffect, useState } from 'react';
import GoogleLogin, { GoogleLoginResponse } from 'react-google-login';
import {useDispatch, useSelector} from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { gapi } from 'gapi-script';

import {postData} from "../../actions/actions";
import Button from '../../components/Buttons/Button/Button';
import GoogleButton from '../../components/Buttons/GoogleButton/GoogleButton';
import Divider from '../../components/Divider/Divider';
import Input from '../../components/Inputs/Input';
import PageLayout from '../../components/PageLayout/PageLayout';
import appDataStorage from '../../functions/appDataStorage';
import {components} from "../../generated/apiTypes";
import EyeOpenIcon from '../../icons/EyeOpenIcon';
import Instance from '../../settings/AxiosInstance';
import {selectUser, setUser} from '../../store/projectSlice';

const instance = new Instance();

const clientId =
  '236802165881-ku3fbreapamlvohgd3ou9j7peg82kukh.apps.googleusercontent.com';

function Login(props: any) {
  const [login, setLogin] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<any>({
    emailError: false,
    email: '',
    passwordError: false,
    password: '',
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (appDataStorage().getData('token')) navigate('/profile');
  }, []);

  const goTo = (path: string, blank?: boolean) => {
    if (!blank) {
      navigate(path);
      return;
    }
    window.open(path, '_blank')?.focus();
  };

  const fetchAuth = async () => {
    try {
      const clearErrors = {
        password: '',
        email: '',
        emailError: false,
        passwordError: false,
      };
      const response = await postData('/auth', {
        email: login,
        password: password,
      });
      if (response && response.status === 201) {
        appDataStorage().setData('token', response.data.session.accessToken);
        appDataStorage().setData('username', response.data?.name);
        dispatch(setUser(response.data));
        if ((response.data as components['schemas']['User'])?.role === 'USER')
          goTo('/profile');
      }
      if (response && response.status === 422) {
        const newObj: any = { ...clearErrors };
        response.data.errors.forEach((item: any) => {
          newObj[item.context.label] = item.message;
        });
        setValidationErrors(newObj);
      }
      if (response && response.status === 400) {
        const newObj: any = { ...clearErrors };
        newObj.emailError = true;
        newObj.password = response.data.message;
        setValidationErrors(newObj);
      }
    } catch (e) {
      console.log('fetchAuthError', e);
    }
  };

  const onSuccess = async (res: any) => {
    const response = await instance.post('/auth/social', {
      token: res.tokenId,
    });

    if (response && response.status === 201) {
      dispatch(setUser(response.data));
      appDataStorage().setData('username', response.data?.name);
      if ((response.data as components['schemas']['User'])?.role === 'USER')
        goTo('/profile');
    }

    if (response && response.status === 400) {
      const newObj: any = { ...validationErrors };
      newObj.emailError = true;
      newObj.password = response.data.message;
      setValidationErrors(newObj);
    }
  };

  const onFailure = (response: GoogleLoginResponse) => {
    console.log('onFailure', response);
  };

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: clientId,
        scope: '',
      });
    }
    gapi.load('client:auth2', start);
  });

  return (
    <PageLayout>
      <Divider height="70px" />
      <div className="login__container">
        <Divider className="divider__horizontal"></Divider>
        <div className="login__content">
          <div className="form__header">
            <h1>Вход</h1>
          </div>
          <div className="form__header-note">
            <p className="">
              Если у вас уже есть аккаунт, введите данные или воспользуйтесь
              входом через Google в зависимости от способа регистрации.
            </p>
          </div>
          <div className="form">
            <div className="form__input">
              <label htmlFor="email">
                <h5 className="mb-4">
                  Email<span className="dot">*</span>
                </h5>
              </label>
              <Input
                type={'text'}
                value={login}
                handleChange={setLogin}
                errorText={validationErrors.email}
                isError={validationErrors.emailError}
              />
            </div>
            <div className="form__input">
              <label htmlFor="password">
                <h5 className="mb-4">
                  Пароль<span className="dot">*</span>
                </h5>
              </label>
              <Input
                type={'password'}
                value={password}
                handleChange={setPassword}
                icon={
                  <button onClick={() => setShowPassword(!showPassword)}>
                    <EyeOpenIcon />
                  </button>
                }
                isError={validationErrors.passwordError}
                errorText={validationErrors.password}
              />
            </div>
            <div className="body__text-16 mb-48">
              Забыли пароль?{' '}
              <a className="body__link" href="/recover">
                Восстановить пароль
              </a>
            </div>
            <button type="submit" className="mb-48">
              <Button onClick={() => fetchAuth()} children={<p>Войти</p>} />
            </button>
            <div className="body__text-16 mb-48">
              Нет аккаунта?{' '}
              <a className="body__link" href="/registration">
                Зарегистрироваться
              </a>
            </div>
            <div className="mb-48">
              <GoogleLogin
                clientId={clientId}
                render={renderProps => (
                  <GoogleButton onClick={renderProps.onClick} />
                )}
                buttonText="Login"
                onSuccess={onSuccess}
                onFailure={onFailure}
              />
            </div>

            <div className="registration__checkbox mb-24">
              <div className="body__text-16">
                <p>
                  Нажимая "Войти", я соглащаюсь <br />с{' '}
                  <a className="body__link" href="/" target="_blank">
                    политикой обработки персональных данных, <br />
                  </a>
                  даю&nbsp;
                  <a className="body__link" href="/" target="_blank">
                    согласие на обработку персональных данных, <br />
                  </a>
                  соглашаюсь с условиями&nbsp;
                  <a className="body__link" href="/" target="_blank">
                    оферты <br />
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <Divider className="divider__horizontal"></Divider>
      </div>
    </PageLayout>
  );
}

export default Login;
