import * as React from "react";

function EyeOpenIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 18c5.523 0 10-6 10-6s-4.477-6-10-6-10 6-10 6 4.477 6 10 6z"
        stroke="#5E5D67"
        strokeWidth={2}
        strokeLinejoin="round"
      />
      <path
        d="M12 14.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5z"
        stroke="#5E5D67"
        strokeWidth={2}
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default EyeOpenIcon;
