import React from "react";

import Button from "../../../../components/Buttons/Button/Button";

import "./style.scss";

interface IDesignerOptionsProps {
  showContactForm(): void;
}

const DesignerOptions: React.FC<IDesignerOptionsProps> = ({
  showContactForm,
}) => {
  return (
    <div className="customer-options__container">
      <div className="info-page__container">
        <span className="customer__subtitle">Возможности</span>
        <div className="customer-options__content">
          <div className="customer-options__content-column">
            <div className="customer-options__content-column-top">
              <div className="info__options-block">
                <span className="info__options-number">01</span>
                <span className="info__options-subtitle">
                  Оформляйте портфолио
                </span>
                <span className="info__options-desc">
                  Заполняйте личный кабинет, публикуйте результаты своей работы
                  в&nbsp;одном месте.
                </span>
              </div>
              <div className="info__options-block">
                <span className="info__options-number">02</span>
                <span className="info__options-subtitle">
                  Находите новых клиентов
                </span>
                <span className="info__options-desc">
                  Получайте новые заказы на&nbsp;дизайн-проект в&nbsp;сервисе,
                  отправляйте отклики самостоятельно и&nbsp;делайте оценку.
                </span>
              </div>
              <div className="info__options-block">
                <span className="info__options-number">03</span>
                <span className="info__options-subtitle">
                  Продавайте готовые дизайн-проекты Ремонту.Просто
                </span>
                <span className="info__options-desc">
                  Получайте дополнительный доход от&nbsp;продажи готовых
                  дизайн-проектов сервису Ремонт.Просто напрямую по&nbsp;полной
                  стоимости.
                </span>
              </div>
            </div>
            <div className="info__contacts-btn lg-contacts-btn">
              <Button>Оставить контакты</Button>
            </div>
          </div>
          <div className="customer-options__content-column">
            <div className="customer-options__content-column-top">
              <div className="info__options-block">
                <div className="info__options-img img5" />
              </div>
            </div>
            <div className="info__contacts-btn sm-contacts-btn">
              <Button onClick={showContactForm}>Оставить контакты</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesignerOptions;
