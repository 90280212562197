import React from "react";

import './style.scss';

interface IPartnersOptions {
  containerRef: React.RefObject<HTMLDivElement>;
}

const PartnersOptions: React.FC<IPartnersOptions> = ({ containerRef }) => {
  return (
    <div className="partners-options__container" ref={containerRef}>
      <div className="customer__title">ПАРТНЁРАМ</div>
      <div className="customer__subtitle">
        Производителям строительных товаров и мебели
      </div>
      <div className="partners-options__content">
        <div className="partners-options__block">
          <span className="info__options-number">01</span>
          <span className="info__options-desc">
            Продавайте материалы без&nbsp;посредников.
          </span>
        </div>
        <div className="partners-options__block">
          <span className="info__options-number">02</span>
          <span className="info__options-desc">
            Рекламируйте свои товары напрямую целевой аудитории.
          </span>
        </div>
        <div className="partners-options__block">
          <span className="info__options-number">03</span>
          <span className="info__options-desc">
            Получайте реальную статистику и&nbsp;прогнозные показатели по спросу
            и&nbsp;востребованности материалов.
          </span>
        </div>
      </div>
      <div className="customer__subtitle banks-subtitle">
        Банкам и&nbsp;страховым компаниям
      </div>
      <div className="info__options-desc banks-desc">
        Получайте полную и&nbsp;прозрачную информацию об&nbsp;объекте:
        используемые материалы, инженерные решения, затраты и&nbsp;гарантии
        на&nbsp;все составляющие ремонта.
      </div>
    </div>
  );
};

export default PartnersOptions;
