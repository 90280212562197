import * as React from "react";

function VkIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.8 7.74c.13-.42 0-.74-.62-.74h-2.02c-.52 0-.75.27-.88.57 0 0-1.03 2.51-2.49 4.15-.48.47-.69.62-.95.62-.13 0-.34-.15-.34-.58V7.74c0-.51-.12-.74-.55-.74H9.76c-.32 0-.51.24-.51.47 0 .48.75.6.8 1.97v2.98c0 .66-.12.78-.37.78-.68 0-2.36-2.53-3.35-5.41-.2-.56-.39-.79-.91-.79H3.39c-.57 0-.69.27-.69.57 0 .54.69 3.2 3.2 6.72C7.57 16.7 9.93 18 12.08 18c1.29 0 1.45-.29 1.45-.79v-1.82c0-.57.12-.69.53-.69.3 0 .81.15 2.01 1.3 1.38 1.38 1.6 2 2.38 2h2.02c.58 0 .87-.29.71-.86-.18-.57-.84-1.4-1.71-2.38-.47-.55-1.18-1.15-1.4-1.46-.3-.38-.21-.55 0-.9 0 0 2.47-3.47 2.73-4.66z"
        fill="#F5F4F8"
      />
    </svg>
  );
}

export default VkIcon;
