import React from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '../../components/Buttons/Button/Button';
import Divider from '../../components/Divider/Divider';
import PageLayout from '../../components/PageLayout/PageLayout';
import CautionIcon from '../../icons/CautionIcon';
import MailIcon from '../../icons/MainIcon';

const Recover = () => {
  const navigate = useNavigate();

  const goTo = (path: string, blank?: boolean) => {
    if (!blank) {
      navigate(path);
      return;
    }
    window.open(path, '_blank')?.focus();
  };
  return (
    <PageLayout>
      <div className="recover__container">
        <Divider className="divider__horizontal"></Divider>
        <div className="recover__content">
          <div className="form__header">
            <h1>Восстановить пароль</h1>
          </div>
          <div className="recover__info">
            <MailIcon className="recover__info-icon" />
            <div className="body__text-16">
              Ссылка для потверждения электронной почты <br />
              была отправлена на адрес <span>vasiliev@gmail.com</span>.<br />
              Убедитесь, что письмо не попало в папку «Спам».
            </div>
          </div>
          <div className="recover__info">
            <CautionIcon className="recover__info-icon" />
            <div className="body__text-16">
              Для завершения процедуры восстановления пароля <br />
              нажмите на ссылку в полученном <br />
              электронном письме.
            </div>
          </div>
          <Button onClick={() => goTo('/')} children="Отправить ссылку повторно" />
        </div>
        <Divider className="divider__horizontal"></Divider>
      </div>
    </PageLayout>
  );
};

export default Recover;
