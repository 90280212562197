import * as React from "react";

function UpIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={60}
      height={60}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M30 55C43.8071 55 55 43.8071 55 30C55 16.1929 43.8071 5 30 5C16.1929 5 5 16.1929 5 30C5 43.8071 16.1929 55 30 55Z"
        fill="white"
        stroke="#686ACA"
        stroke-width="3.5"
        stroke-linejoin="round"
      />
      <path
        d="M38.75 27.5L30 18.75L21.25 27.5"
        stroke="#686ACA"
        stroke-width="3.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M38.75 38.75L30 30L21.25 38.75"
        stroke="#686ACA"
        stroke-width="3.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default UpIcon;
