import React from 'react';
// import SideMenu from "../../components/SideMenu/SideMenu";
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

import Button from '../../components/Buttons/Button/Button';
import Divider from '../../components/Divider/Divider';
import PageLayout from '../../components/PageLayout/PageLayout';
import AvatarIcon from '../../icons/AvatarIcon';
import EditIcon from '../../icons/EditIcon';

const ProfileCustomerMain = () => {
  const navigate = useNavigate();

  const goTo = (path: string, blank?: boolean) => {
    if (!blank) {
      navigate(path);
      return;
    }
    window.open(path, '_blank')?.focus();
  };
  return (
    <PageLayout>
      <div className="profile__container">
        <Divider className="divider__horizontal"></Divider>
        <div className="profile__content">
          <div className="profile__right-wrapper-large">
            <div className="profile__right-content">
              <div className="profile__userinfo mb-66">
                <div className="profile__avatar">
                  <div className="profile__avatar-img"></div>
                </div>
                <div className="profile__about">
                  <h4>Алексей Васильев</h4>
                  <div className="profile__about-email">vasiliev@gmail.com</div>
                </div>
              </div>

              <div className="profile__info">
                <div className="profile__info-section mb-24">
                  <h5 className="mb-6">Телефон:</h5>
                  <div className="body__16">+7 (921) 123- 45-57</div>
                </div>
                <div className="profile__info-section mb-24">
                  <h5 className="mb-6">Город:</h5>
                  <div className="body__16">Санкт-Петербург</div>
                </div>
                <div className="profile__info-section mb-60">
                  <h5 className="mb-6">Статус:</h5>
                  <div className="body__16">Частное лицо</div>
                </div>
              </div>

              {/* <div className="profile__notification">
                <h5 className="profile__notification-title">
                  Получать уведомления
                </h5>
                <div className="profile__notification-block mb-60">
                  <div className="profile__checkboxes">
                    <div className="profile__checkbox mb-18">
                      <input
                        className="checkbox"
                        type="checkbox"
                        id='"telegram"'
                      />
                      <label className="body__text-16" htmlFor="telegram">
                        Telegram{" "}
                      </label>
                    </div>
                    <div className="profile__checkbox">
                      <input
                        className="checkbox"
                        type="checkbox"
                        id='"email"'
                      />
                      <label className="body__text-16" htmlFor="email">
                        Email{" "}
                      </label>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="mb-120">
                <Button onClick={() => goTo('/profile')} children="Вернуться" />
              </div>
            </div>
            <Divider className="divider__horizontal"></Divider>
          </div>
        </div>
        <Divider className="divider__horizontal"></Divider>
      </div>
    </PageLayout>
  );
};

export default ProfileCustomerMain;
