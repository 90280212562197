import React from "react";

import Footer from "../../components/Footer/Footer";
import UpIcon from "../../icons/UpIcon";
import ContactForm from "./components/ContactForm/contactForm";
import CustomerAdvantages from "./components/CustomerAdvantages/customerAdvantages";
import CustomerOptions from "./components/CustomerOptions/customerOptions";
import DesignerAdvantages from "./components/DesignerAdvantages/designerAdvantages";
import DesignerOptions from "./components/DesignerOptions/designerOptions";
import Header from "./components/Header/header";
import Intro from "./components/Intro/intro";
import PartnersOptions from "./components/PartnersOptions/partnersOptions";
import Steps from "./components/Steps/steps";
import WorkerAdvantages from "./components/WorkerAdvantages/workerAdvantages";
import WorkerOptions from "./components/WorkerOptions/workerOptions";

import "./style.scss";

export type InfoBlock =
  | "MAIN"
  | "CUSTOMER"
  | "WORKER"
  | "DESIGNER"
  | "PARTNERS";

const InfoPage: React.FC = () => {
  const mainRef = React.useRef<HTMLDivElement>(null);
  const customerRef = React.useRef<HTMLDivElement>(null);
  const workerRef = React.useRef<HTMLDivElement>(null);
  const designerRef = React.useRef<HTMLDivElement>(null);
  const partnerRef = React.useRef<HTMLDivElement>(null);
  const [showContactForm, setShowContactForm] = React.useState<boolean>(false);
  const scrollToBlock = (block: InfoBlock) => {
    switch (block) {
      case "MAIN":
        mainRef?.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
        break;
      case "CUSTOMER":
        customerRef?.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
        break;
      case "WORKER":
        workerRef?.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
        break;
      case "DESIGNER":
        designerRef?.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
        break;
      case "PARTNERS":
        partnerRef?.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
        break;
      default:
        break;
    }
  };
  return (
    <>
      {showContactForm && (
        <ContactForm hideContactForm={() => setShowContactForm(false)} />
      )}
      <Header
        scrollToBlock={scrollToBlock}
        showContactForm={() => setShowContactForm(true)}
      />
      <div className="info-page__container">
        <Intro
          containerRef={mainRef}
          showContactForm={() => setShowContactForm(true)}
        />
        <CustomerAdvantages containerRef={customerRef} />
      </div>
      <CustomerOptions showContactForm={() => setShowContactForm(true)} />
      <div className="info-page__container">
        <Steps />
        <WorkerAdvantages containerRef={workerRef} />
      </div>
      <WorkerOptions showContactForm={() => setShowContactForm(true)} />
      <div className="info-page__container">
        <DesignerAdvantages containerRef={designerRef} />
      </div>
      <DesignerOptions showContactForm={() => setShowContactForm(true)} />
      <div className="info-page__container">
        <PartnersOptions containerRef={partnerRef} />
      </div>
      <button
        className="info-page__up-btn"
        onClick={() => scrollToBlock("MAIN")}
      >
        <UpIcon />
      </button>
      <Footer />
    </>
  );
};

export default InfoPage;
