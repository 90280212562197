import React from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '../../components/Buttons/Button/Button';
import Divider from '../../components/Divider/Divider';
import PageLayout from '../../components/PageLayout/PageLayout';

const Recover = () => {
  const navigate = useNavigate();

  const goTo = (path: string, blank?: boolean) => {
    if (!blank) {
      navigate(path);
      return;
    }
    window.open(path, '_blank')?.focus();
  };

  return (
    <PageLayout>
      <Divider height="70px" />
      <div className="recover__container-large">
        <Divider className="divider__horizontal" />
        <div className="recover__content">
          <div className="form__header">
            <h1>Восстановить пароль</h1>
          </div>

          <div className="form">
            <div className="form__input">
              <label htmlFor="email">
                <h5 className="mb-4">
                  Email<span className="dot">*</span>
                </h5>
              </label>
              <input id="email" className="form__input-item mb-48" type="email" />

              <Button onClick={() => goTo('/recover/finish')} children="Восстановить пароль" />
            </div>
          </div>
        </div>
        <Divider className="divider__horizontal"></Divider>
      </div>
    </PageLayout>
  );
};

export default Recover;
