import * as React from "react";

function EditIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={25}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.5 5l4 4M4 16.5l14-14 4 4-14 14-5 1 1-5zM15.5 5l4 4M4.5 16.5L8 20M6.5 18l11-11"
        stroke="#5E5D67"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default EditIcon;
