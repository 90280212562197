import * as React from "react";

function SearchIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.5 19a8.5 8.5 0 100-17 8.5 8.5 0 000 17z"
        stroke="#5E5D67"
        strokeWidth={2}
        strokeLinejoin="round"
      />
      <path
        d="M13.328 7.172A3.987 3.987 0 0010.5 6 3.988 3.988 0 007.67 7.172M16.61 16.61l4.244 4.244"
        stroke="#5E5D67"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SearchIcon;
