import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from "./configureStore";
import { initialState } from './initialState';
import { IProjectSlice, IStore } from './types';
const projectInitialState = initialState as IStore;

//В зависимости от типа проекта, можно масштабировать слайсы
export const projectSlice = createSlice({
  name: 'project',
  initialState: {
    user: projectInitialState.user,
  },
  reducers: {
    setUser: (state, action: PayloadAction<IProjectSlice['user']>) => {
      return {
        ...state,
        user: action.payload,
      };
    },
    clearUser: state => {
      return {
        ...state,
        user: null,
      };
    },
    handleErrors: (
      state,
      action: PayloadAction<IProjectSlice['responseErrors']>,
    ) => {
      return {
        ...state,
        responseErrors: action.payload,
      };
    },
  },
});

export const { setUser, clearUser, handleErrors } = projectSlice.actions;

const selectSelf = (state: RootState) => state.project;

export const selectUser = createSelector(selectSelf, project => project.user);

export default projectSlice.reducer;
