import * as React from "react";

function AvatarIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={72}
      height={72}
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M36 66c16.569 0 30-13.431 30-30C66 19.431 52.569 6 36 6 19.431 6 6 19.431 6 36c0 16.569 13.431 30 30 30z"
        stroke="#5E5D67"
        strokeWidth={3.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36 34.5a7.5 7.5 0 100-15 7.5 7.5 0 000 15z"
        stroke="#5E5D67"
        strokeWidth={3.5}
        strokeLinejoin="round"
      />
      <path
        d="M15.033 57.498C15.55 49.681 22.053 43.5 30 43.5h12c7.937 0 14.435 6.164 14.965 13.967"
        stroke="#5E5D67"
        strokeWidth={3.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default AvatarIcon;
