import { AxiosResponse } from 'axios';
import { AnyAction } from 'redux';

import Instance from '../settings/AxiosInstance';
import store from '../store/configureStore';

const instance = new Instance();
const dispatch = store.dispatch;

async function checkStatuses(action: (e?: any) => AnyAction, response: any) {
  if (response) {
    const status = response.status;

    if (status === 200 || status === 201) {
      const data = response.data.results
        ? response.data.results
        : response.data;
      dispatch(action(data));
    }

    if (status === 204) {
      dispatch(action());
    }
  }
}

export const getData = async (
  url: any,
  action?: <Type>(e?: Type) => AnyAction,
): Promise<AxiosResponse | undefined> => {
  const response: AxiosResponse | undefined = await instance?.get(url);
  if (!response) return;
  if (!action) {
    return response;
  }
  await checkStatuses(action, response);
};

export const postData = async <Type>(
  url: any,
  data?: Type,
  type?: string | undefined,
  action?: <Type>(e?: Type) => AnyAction,
): Promise<AxiosResponse | undefined> => {
  const response: AxiosResponse | undefined = await instance?.post(
    url,
    data,
    type,
  );
  if (!response) return;
  if (!action) {
    return response;
  }
  await checkStatuses(action, response);
};

export const putData = async <Type>(
  url: any,
  data?: Type,
  type?: string | undefined,
  action?: <Type>(e?: Type) => AnyAction,
): Promise<AxiosResponse | undefined> => {
  const response: AxiosResponse | undefined = await instance?.put(
    url,
    data,
    type,
  );
  if (!response) return;
  if (!action) {
    return response;
  }
  await checkStatuses(action, response);
};

export const patchData = async <Type>(
  url: any,
  data: Type,
  type?: string | undefined,
  action?: <Type>(e?: Type) => AnyAction,
): Promise<AxiosResponse | undefined> => {
  const response: AxiosResponse | undefined = await instance?.patch(
    url,
    data,
    type,
  );
  if (!response) return;
  if (!action) {
    return response;
  }
  await checkStatuses(action, response);
};

export const deleteData = async (
  url: any,
  action?: <Type>(e?: Type) => AnyAction,
): Promise<AxiosResponse | undefined> => {
  const response: AxiosResponse | undefined = await instance?.delete(url);
  if (!response) return;
  if (!action) {
    return response;
  }
  await checkStatuses(action, response);
};

export const abortData = (url: string) => {
  instance?.abort(url);
};
