import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import InfoPage from './pages/InfoPage/info';
import Login from './pages/Login/Login';
import ProfileCustomerOther from './pages/ProfileCustomer/ProfileCustomerOther';
import ProfileCustomerPassword from './pages/ProfileCustomer/ProfileCustomerPassword';
import ProfileMain from './pages/ProfileCustomer/ProfileMain';
import Recover from './pages/Recover/Recover';
import RecoverFinish from './pages/Recover/RecoverFinish';
import RecoverNewPassword from './pages/Recover/RecoverNewPassword';
import Registration from './pages/Registration/Registration';
import RegistrationComplete from './pages/Registration/RegistrationComplete';
import RegistrationConfirm from './pages/Registration/RegistrationConfirm';
import RegistrationRoleSelect from './pages/Registration/RegistrationRoleSelect';
import RegistrationRoleStatusSelect from './pages/Registration/RegistrationRoleStatusSelect';

import './pages/style.scss';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<InfoPage />} />
        {process.env['REACT_APP_ENVIRONMENT'] === 'production' ? null : (
          <>
            {/* login */}
            <Route path="/login" element={<Login />} />
            {/* registration */}
            <Route path="/registration" element={<Registration />} />
            <Route
              path="/registration/confirm"
              element={<RegistrationConfirm />}
            />
            <Route
              path="/registration/role"
              element={<RegistrationRoleSelect />}
            />
            <Route
              path="/registration/verify/:userUid"
              element={<RegistrationComplete />}
            />
            <Route
              path="/registration/role/status"
              element={<RegistrationRoleStatusSelect />}
            />
            {/* recover */}
            <Route path="/recover" element={<Recover />} />
            <Route path="/recover/finish" element={<RecoverFinish />} />
            <Route
              path="/recover/newpassword"
              element={<RecoverNewPassword />}
            />
            {/* profile customer */}
            <Route path="/profile" element={<ProfileMain />} />
            <Route
              path="/profile/newpassword"
              element={<ProfileCustomerPassword />}
            />
            <Route
              path="/profile/customer/other"
              element={<ProfileCustomerOther />}
            />
          </>
        )}
      </Routes>
    </BrowserRouter>
  );
};

export default App;
