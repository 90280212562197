import React, { useState } from "react";
import { Controller,useForm } from "react-hook-form";
import InputMask from "react-input-mask";

import classnames from "classnames";

import { postData } from '../../../../actions/actions';
import Button from '../../../../components/Buttons/Button/Button';
import Checkbox from '../../../../components/Checkbox';
import Input from '../../../../components/Inputs/Input';
import Textarea from '../../../../components/Textarea/Textarea';
import { components } from '../../../../generated/apiTypes';
import CloseIcon from '../../../../icons/CloseIcon';

import "./style.scss";

interface IContactFormProps {
  hideContactForm(): void;
}

const ContactForm: React.FC<IContactFormProps> = ({ hideContactForm }) => {
  const [email, setEmail] = React.useState<string>();
  const [name, setName] = React.useState<string>();
  const [comment, setComment] = React.useState<string>();
  const [checkboxValue1, setCheckboxValue1] = React.useState<boolean>(false);
  const [checkboxValue2, setCheckboxValue2] = React.useState<boolean>(false);
  const [checkboxValue3, setCheckboxValue3] = React.useState<boolean>(false);
  const [checkboxValue4, setCheckboxValue4] = React.useState<boolean>(false);
  const [checkboxValue5, setCheckboxValue5] = React.useState<boolean>(false);
  const [policyError, setPolicyError] = React.useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<any>({
    email: '',
    name: '',
    phone: '',
    emailError: false,
    nameError: false,
    phoneError: false,
  });
  const { control } = useForm();
  const phoneRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (checkboxValue5 && policyError) setPolicyError(false);
  }, [checkboxValue5]);

  const fetchColdCalling = async () => {
    if (!checkboxValue5) setPolicyError(true);
    else
      try {
        const clearErrors = {
          email: '',
          name: '',
          phone: '',
          emailError: false,
          nameError: false,
          phoneError: false,
        };
        setValidationErrors(clearErrors);
        const response = await postData<
          components['schemas']['ColdCallingCreate']
        >('/cold_calling', {
          name: name as string,
          email: email as string,
          phone:
            phoneRef?.current?.value
              .replace('(', '')
              .replace(')', '')
              .replaceAll('-', '')
              .replaceAll('_', '') || undefined,
          isFirm: checkboxValue1,
          isUser: checkboxValue2,
          isWorker: checkboxValue3,
          isDesigner: checkboxValue4,
          comment: comment,
        });
        if (response && response.status === 201) {
          hideContactForm();
        }
        if (response && response.status === 422) {
          const newObj: any = { ...clearErrors };
          response.data.errors.forEach((item: any) => {
            newObj[item.context.label] = item.message;
          });
          setValidationErrors(newObj);
        }
      } catch (e) {
        console.log('fetchColdCallingError', e);
      }
  };

  return (
    <div className="contact-form__background">
      <div className="contact-form__container">
        <button className="contact-form__close-btn" onClick={hideContactForm}>
          <CloseIcon className="contact-form__close-icon" />
        </button>
        <div className="contact-form__form">
          <div className="contact-form__title">Оставить контакты</div>
          <div className="contact-form__input">
            <h5 className="mb-4">
              Имя<span className="dot">*</span>
            </h5>
            <Input
              value={name}
              handleChange={setName}
              errorText={validationErrors.name}
              isError={validationErrors.nameError}
            />
          </div>
          <div className="contact-form__input">
            <h5 className="mb-4">
              Email<span className="dot">*</span>
            </h5>
            <Input
              value={email}
              handleChange={setEmail}
              errorText={validationErrors.email}
              isError={validationErrors.emailError}
            />
          </div>
          <>
            <h5 className="mb-4">Телефон</h5>
            <div className={classnames('input__container')}>
              <div
                className={classnames(
                  'input__content',
                  (validationErrors.phone || validationErrors.phoneError) &&
                    'input-error',
                )}
              >
                <Controller
                  name="reactMaskInput"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputMask
                      mask="+7(999)999-99-99"
                      value={value}
                      onChange={onChange}
                      ref={phoneRef}
                    >
                      {inputProps => (
                        <input
                          {...inputProps}
                          type="tel"
                          className="input"
                          disableUnderline
                        />
                      )}
                    </InputMask>
                  )}
                />
              </div>
              {validationErrors.phone ? (
                <p className="input__text-error">{validationErrors.phone}</p>
              ) : (
                <></>
              )}
            </div>
          </>
          <div className="contact-form__options">
            <Checkbox
              checkedValue={checkboxValue1}
              setCheckedValue={setCheckboxValue1}
              label="Представляю юридическое лицо"
            />
            <Checkbox
              checkedValue={checkboxValue2}
              setCheckedValue={setCheckboxValue2}
              label="Хочу заказать ремонт"
            />
            <Checkbox
              checkedValue={checkboxValue3}
              setCheckedValue={setCheckboxValue3}
              label="Хочу получать заказы на ремонт"
            />
            <Checkbox
              checkedValue={checkboxValue4}
              setCheckedValue={setCheckboxValue4}
              label="Готовлю дизайн-проекты"
            />
          </div>
          <div className="contact-form__comment">
            <div className="contact-form__input">
              <h5 className="mb-4">Комментарий</h5>
              <Textarea
                value={comment}
                handleChange={setComment}
                placeholder="Задайте вопрос или напишите пожелания"
                rows={4}
                maxLength={1000}
              />
            </div>
          </div>
          <Button onClick={() => fetchColdCalling()}>Отправить</Button>
          <div className="contact-form__policy">
            <Checkbox
              checkedValue={checkboxValue5}
              setCheckedValue={setCheckboxValue5}
              error={policyError}
            >
              <span className="checkbox-label">Ознакомлен и согласен с </span>
              <a
                className="checkbox-link"
                href="/Политика_обработки_персональных_данных.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                политикой обработки персональных данных
              </a>
              <span className="checkbox-label">, даю </span>
              <a
                className="checkbox-link"
                href="/Согласие_на_обработку_персональных_данных.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                согласие на обработку персональных данных
              </a>
            </Checkbox>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
