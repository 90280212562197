import * as React from 'react';

function HouseIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={25}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.5 22V10l-10-8-10 8v12h6v-9h8v9h6z"
        stroke="#5E5D67"
        strokeWidth={2}
        strokeLinejoin="round"
      />
      <path
        d="M12.5 22v-5"
        stroke="#5E5D67"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default HouseIcon;
