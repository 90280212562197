import React from "react";

import Button from "../../../../components/Buttons/Button/Button";

import "./style.scss";

interface IWorkerOptionsProps {
  showContactForm(): void;
}

const WorkerOptions: React.FC<IWorkerOptionsProps> = ({ showContactForm }) => {
  return (
    <div className="customer-options__container">
      <div className="info-page__container">
        <span className="customer__subtitle">Возможности</span>
        <div className="customer-options__content">
          <div className="customer-options__content-column">
            <div className="info__options-block">
              <span className="info__options-number">01</span>
              <span className="info__options-subtitle">
                Находите новые заказы
              </span>
              <span className="info__options-desc">
                Получайте новые заказы на&nbsp;ремонт в&nbsp;сервисе,
                отправляйте отклик и&nbsp;делайте расчёт.
              </span>
            </div>
            <div className="info__options-block">
              <span className="info__options-number">02</span>
              <span className="info__options-subtitle">
                Заполняйте свой профиль и&nbsp;ведите портфолио
              </span>
              <span className="info__options-desc">
                Храните полную информацию о&nbsp;себе и&nbsp;своих работах
                в&nbsp;личном профиле.
              </span>
            </div>
            <div className="info__options-block">
              <span className="info__options-number">03</span>
              <span className="info__options-subtitle">
                Формируйте смету и&nbsp;план работ в&nbsp;системе
              </span>
              <span className="info__options-desc">
                Используйте справочник работ и&nbsp;материалов, заполняйте
                собственный прайс-лист и&nbsp;формируйте смету с&nbsp;помощью
                удобного конструктора. Достаточно заполнить один раз, чтобы
                в&nbsp;дальнейшем тратить меньше времени.
              </span>
            </div>
            <div className="info__options-block">
              <span className="info__options-number">04</span>
              <span className="info__options-subtitle">
                Закупайте материалы и&nbsp;заказывайте доставку
              </span>
              <span className="info__options-desc">
                Ремонт.Просто поможет с&nbsp;закупкой материалов
                и&nbsp;логистикой к&nbsp;нужной дате и&nbsp;времени.
              </span>
            </div>
            <div className="info__options-img img3" />
          </div>
          <div className="customer-options__content-column">
            <div className="customer-options__content-column-top">
              <div className="info__options-block">
                <div className="info__options-img img4" />
              </div>
              <div className="info__options-block">
                <span className="info__options-number">05</span>
                <span className="info__options-subtitle">
                  Автоматически формируйте отчетность по&nbsp;работе
                </span>
                <span className="info__options-desc">
                  Ведите всю отчётность по&nbsp;объекту в&nbsp;сервисе: работы,
                  материалы, чеки. Даже если заказчик забыл, что согласовывал
                  какие-то работы, у&nbsp;вас всегда будет подтверждение.
                </span>
              </div>
              <div className="info__options-block">
                <span className="info__options-number">06</span>
                <span className="info__options-subtitle">
                  Находите сотрудников в&nbsp;вашу бригаду
                </span>
                <span className="info__options-desc">
                  Ремонт.Просто поможет подобрать сотрудников для&nbsp;вашей
                  бригады по&nbsp;собственной базе, когда ресурсов
                  не&nbsp;хватает.
                </span>
              </div>
              <div className="info__options-block">
                <span className="info__options-number">07</span>
                <span className="info__options-subtitle">
                  Поддержка с&nbsp;расшифровкой проектов
                </span>
                <span className="info__options-desc">
                  Ремонт.Просто поможет расшифровать чертежи на&nbsp;объекте.
                </span>
              </div>
            </div>
            <div className="info__contacts-btn">
              <Button onClick={showContactForm}>Оставить контакты</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkerOptions;
