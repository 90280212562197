import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import appDataStorage from "../../functions/appDataStorage";
import AvatarIcon from "../../icons/AvatarIcon";
import LogoIcon from '../../icons/LogoIcon';
import { selectUser } from '../../store/projectSlice';
import Button from '../Buttons/Button/Button';
import Divider from '../Divider/Divider';
import UserInfo from "./UserInfo";

const Header = () => {
  return (
    <div className="header__container">
      <Divider className="divider__horizontal" />
      <div className="header__content">
        <Link to="/" className="header__logo">
          <div className="header__logo-icon">
            <LogoIcon />
          </div>
          <div className="header__logo-title">Ремонт.Просто</div>
        </Link>
        <div className="header__links">
          <div className="header__link">
            <Link to="/login" className="link">
              Заказчикам
            </Link>
          </div>
          <div className="header__link">
            <Link to="/login" className="link">
              Прорабам
            </Link>
          </div>
          <div className="header__link">
            <Link to="#" className="link">
              Дизайнерам
            </Link>
          </div>
          <div className="header__link">
            <Link to="#" className="link">
              Партнерам
            </Link>
          </div>
        </div>
        {appDataStorage().getData('token') && <UserInfo />}
      </div>
      <Divider className="divider__horizontal" />
    </div>
  );
};

export default Header;
