import React from 'react';
import { useLocation,useNavigate } from 'react-router-dom';

import PageLayout from '../../components/PageLayout/PageLayout';
import useUser from './hooks/use-user';
import ProfileCustomerEdit from "./ProfileCustomerEdit";
import ProfileCustomerFilled from './ProfileCustomerView';

const ProfileMain: React.FC = () => {
  useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const [mode, setMode] = React.useState<'VIEW' | 'EDIT'>();

  const goTo = (path: string, blank?: boolean) => {
    if (!blank) {
      navigate(path);
      return;
    }
    window.open(path, '_blank')?.focus();
  };

  React.useEffect(() => {
    const edit = (location.state as { edit: boolean })?.edit;
    if (edit) {
      setMode('EDIT');
    } else setMode('VIEW');
  }, [location.state]);

  return (
    <PageLayout>
      {mode === 'VIEW' && (
        <ProfileCustomerFilled setEditMode={() => setMode('EDIT')} />
      )}
      {mode == 'EDIT' && (
        <ProfileCustomerEdit setViewMode={() => setMode('VIEW')} />
      )}
    </PageLayout>
  );
};

export default ProfileMain;
