import * as React from "react";

function TelegramIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.772 19.093l.28-4.196 7.67-6.863c.339-.308-.07-.456-.52-.188l-9.468 5.94-4.094-1.289c-.88-.248-.89-.853.2-1.29l15.948-6.109c.73-.327 1.429.179 1.149 1.29l-2.716 12.705c-.19.902-.74 1.12-1.498.704l-4.135-3.035-1.987 1.914c-.23.228-.42.417-.83.417z"
        fill="#F5F4F8"
      />
    </svg>
  );
}

export default TelegramIcon;
