import React from "react";
import { Link } from "react-router-dom";

import CommentIcon from "../../icons/CommentIcon";
import HeartIcon from "../../icons/HeartIcon";
import HelpIcon from "../../icons/HelpIcon";
import HomeIcon from "../../icons/HomeIcon";
import PeopleIcon from "../../icons/PeopleIcon";
import SearchIcon from "../../icons/SearchIcon";

const SideMenu = () => {
  return (
    <div className="sidemenu__left-wrapper">
      <div className="sidemenu__left-content">
        <div className="sidemenu__menu-item">
          <div className="sidemenu__menu-icon">
            <HomeIcon />
          </div>
          <div className="sidemenu__menu-text menu__header">Мои объекты</div>
        </div>
        <div className="sidemenu__menu-item ">
          <div className="sidemenu__menu-icon">
            <SearchIcon />
          </div>
          <div className="sidemenu__menu-text menu__header">
            Поиск исполнителя
          </div>
        </div>
        <div className="sidemenu__menu-item">
          <div className="sidemenu__menu-icon">
            <HeartIcon />
          </div>
          <div className="sidemenu__menu-text menu__header">Избранное</div>
        </div>
        <div className="sidemenu__menu-item">
          <div className="sidemenu__menu-icon">
            <HelpIcon />
          </div>
          <div className="sidemenu__menu-text menu__header">
            Служба поддержки
          </div>
        </div>
        <div className="sidemenu__menu-item">
          <div className="sidemenu__menu-icon">
            <PeopleIcon />
          </div>
          <div className="sidemenu__menu-text menu__header">
            <Link to="/profile">Профиль</Link>
          </div>
        </div>
        <div className="sidemenu__menu-item">
          <div className="sidemenu__menu-icon">
            <CommentIcon />
          </div>
          <div className="sidemenu__menu-text menu__header">Чат</div>
        </div>
      </div>
    </div>
  );
};

export default SideMenu;
