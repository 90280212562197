import React from "react";

import "./style.scss";

const Steps: React.FC = () => {
  return (
    <div className="info__steps-container">
      <span className="customer__subtitle">Как это работает?</span>
      <div className="info__steps-content">
        <div className="info__steps-column">
          <div className="info__steps-img" />
        </div>
        <div className="info__steps-column">
          <span className="info__steps-desc">
            Для начала нажмите 4 кнопки в системе:
          </span>
          <div className="info__steps-numbers" />
          <span className="info__steps-desc">
            Остальное исполнитель сделает с&nbsp;помощью Ремонт.Просто
            самостоятельно. Вам нужно будет только заходить и&nbsp;следить
            за&nbsp;прогрессом ремонта.
          </span>
        </div>
      </div>
    </div>
  );
};

export default Steps;
