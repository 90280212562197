import * as React from "react";

function MailIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2 19.5h20v-15H2v15z"
        stroke="#5E5D67"
        strokeWidth={2}
        strokeLinejoin="round"
      />
      <path
        d="M2 4.5L12 12l10-7.5"
        stroke="#5E5D67"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 4.5H2V12M22 12V4.5H12"
        stroke="#5E5D67"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default MailIcon;
