import React from 'react';

import Footer from '../Footer/Footer';
import Header from '../Header/Header';

function PageLayout(props: any) {
  return (
    <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
      <Header />
      {props.children}
      <Footer />
    </div>
  );
}

export default PageLayout;
