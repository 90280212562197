import React, { ComponentProps, FC} from "react";

import classnames from "classnames";

import Divider from "../Divider/Divider";

import "./textareaStyle.scss";

interface InputProps extends ComponentProps<"textarea"> {
  isError?: boolean;
  errorText?: string;
  handleChange: (e: string) => void;
}

const Textarea: FC<InputProps> = ({
  errorText,
  isError,
  handleChange,
  ...props
}) => {
  const ref = React.useRef<HTMLTextAreaElement>(null);
  return (
    <div className={classnames('textarea__container')}>
      <div
        className={classnames(
          'textarea__content',
          (errorText || isError) && 'textarea-error',
        )}
      >
        <textarea
          {...props}
          ref={ref}
          value={props.value}
          onChange={e => handleChange(e.target.value)}
        />
      </div>
      {props.maxLength && (
        <div className="textarea__length">
          {ref?.current?.value.length || 0}/{props.maxLength}
        </div>
      )}
      {errorText ? <p className="input__text-error">{errorText}</p> : <></>}
    </div>
  );
};

export default Textarea;
