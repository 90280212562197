import React from "react";
import {useDispatch, useSelector} from 'react-redux';

import { getData } from '../../../actions/actions';
import { selectUser, setUser } from '../../../store/projectSlice';

const useUser = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  const fetchUser = async () => {
    try {
      const response = await getData('/profile');
      if (response?.data) dispatch(setUser(response.data));
    } catch (e) {
      //
    }
  };

  React.useEffect(() => {
    if (!user) fetchUser();
  }, []);

  return;
};

export default useUser;
