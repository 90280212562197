import React from 'react';

import classnames from "classnames";

import CheckboxIcon from "./CheckboxIcon";
import CheckedIcon from "./CheckedIcon";

import './style.scss';

interface ICheckboxProps {
  checkedValue: boolean;
  setCheckedValue(value: boolean): void;
  label?: string;
  children?: React.ReactNode;
  error?: boolean;
  disabled?: boolean;
}

const Checkbox: React.FC<ICheckboxProps> = ({
  checkedValue,
  setCheckedValue,
  label,
  error,
  disabled,
  children
}) => {
  const toggleValue = () => {
    setCheckedValue(!checkedValue);
  };
  return (
    <button
      className={classnames('checkbox-row', children && 'start-aligned')}
      onClick={toggleValue}
      disabled={disabled}
    >
      {checkedValue ? (
        <CheckedIcon className="checked-icon" />
      ) : (
        <CheckboxIcon className={classnames('checkbox-icon', { error })} />
      )}
      <div className="checkbox-content">
        {label && <span className="checkbox-label">{label}</span>}
        {children}
      </div>
    </button>
  );
};

export default Checkbox;
