import React from "react";

import "./style.scss";

interface ICustomerAdvantages {
  containerRef: React.RefObject<HTMLDivElement>;
}

const CustomerAdvantages: React.FC<ICustomerAdvantages> = ({
  containerRef,
}) => {
  return (
    <div className="customer-advantages__container" ref={containerRef}>
      <div className="customer__left-col">
        <span className="customer__title">ЗАКАЗЧИКАМ</span>
        <span className="customer__subtitle">Преимущества</span>
        <span className="customer__desc">
          Настроим оптимальный для&nbsp;вас процесс. Проводите больше времени
          с&nbsp;семьёй и&nbsp;занимайтесь своими делами, пока делается ремонт.
        </span>
      </div>
      <div className="customer-advantages__img" />
    </div>
  );
};

export default CustomerAdvantages;
