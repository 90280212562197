import React, { FC } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Button from '../../components/Buttons/Button/Button';
import Divider from '../../components/Divider/Divider';
import PageLayout from '../../components/PageLayout/PageLayout';
import appDataStorage from '../../functions/appDataStorage';
import HouseIcon from '../../icons/HouseIcon';
import WorkerIcon from '../../icons/WorkerIcon';
import Instance from '../../settings/AxiosInstance';
import { RootState } from '../../store/configureStore';
import { setUser } from '../../store/projectSlice';
import { IProjectSlice } from '../../store/types';

const instance = new Instance();

interface RegistrationRoleSelectProps {
  user: IProjectSlice['user'];
}

const RegistrationRoleSelect: FC<RegistrationRoleSelectProps> = ({ user }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const goTo = (path: string, blank?: boolean) => {
    if (!blank) {
      navigate(path);
      return;
    }
    window.open(path, '_blank')?.focus();
  };

  const fetchRegistration = async () => {
    try {
      const response = await instance.post('/registration/user', {
        email: user?.email,
      });
      if (response && response.status === 201) {
        if (response.data.authorizeService) {
          appDataStorage().setData('token', response.data.session.accessToken);
          dispatch(setUser(response.data));
          navigate('/profile', { state: { edit: true } });
        } else {
          goTo('/registration/confirm');
        }
      }

      if (response && response.status === 400) {
        console.log('Error message', response.data.message);
      }
    } catch (e) {
      console.log('fetchAuthError', e);
    }
  };

  return (
    <PageLayout>
      <Divider height="70px" />
      <div className="registration__select__container">
        <div className="registration__select__content">
          <div className="registration__select-card">
            <div className="registration__select-card-title mb-18">
              <HouseIcon />
              <h2 className="ml-26">Заказать ремонт</h2>
            </div>
            <div className="registration__select-card-note mb-42">
              <h5>если вы ищете исполнителя работ</h5>
            </div>
            <div className="registration__select-card-button">
              <Button onClick={() => fetchRegistration()}>
                <p>Хочу заказать ремонт</p>
              </Button>
            </div>
          </div>
          <div className="registration__select-card">
            <div className="registration__select-card-title mb-18">
              <WorkerIcon />
              <h2 className="ml-26">Предложить услуги</h2>
            </div>
            <div className="registration__select-card-note mb-42">
              <h5>если вы ищете заказы</h5>
            </div>
            <div className="registration__select-card-button">
              <Button
                mode="secondary-button"
                onClick={() => goTo('/registration/role/status')}
              >
                <p>Предлагаю услуги по ремонту</p>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: state.project.user,
});

export default connect(mapStateToProps)(RegistrationRoleSelect);
