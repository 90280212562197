import React from "react";

import "./style.scss";

interface IWorkerAdvantages {
  containerRef: React.RefObject<HTMLDivElement>;
}

const WorkerAdvantages: React.FC<IWorkerAdvantages> = ({ containerRef }) => {
  return (
    <div className="worker-advantages__container" ref={containerRef}>
      <div className="customer__title">ПРОРАБАМ</div>
      <div className="customer__subtitle">Преимущества</div>
      <div className="worker-advantages__img-wrapper">
        <div className="customer-advantages__img worker-advantages__img" />
      </div>
    </div>
  );
};

export default WorkerAdvantages;
