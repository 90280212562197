import * as React from "react";

function PeopleIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 10a3.5 3.5 0 100-7 3.5 3.5 0 000 7z"
        stroke="#5E5D67"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 20.4v.6h18v-.6c0-2.24 0-3.36-.436-4.216a4 4 0 00-1.748-1.748C17.96 14 16.84 14 14.6 14H9.4c-2.24 0-3.36 0-4.216.436a4 4 0 00-1.748 1.748C3 17.04 3 18.16 3 20.4z"
        stroke="#3C3C8F"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default PeopleIcon;
