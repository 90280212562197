import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import { useNavigate } from 'react-router-dom';

import {postData} from "../../actions/actions";
import Button from '../../components/Buttons/Button/Button';
import Divider from '../../components/Divider/Divider';
import Input from "../../components/Inputs/Input";
import PageLayout from '../../components/PageLayout/PageLayout';
import {components} from "../../generated/apiTypes";
import EyeOpenIcon from '../../icons/EyeOpenIcon';
import {selectUser, setUser} from "../../store/projectSlice";
import useUser from "./hooks/use-user";

const ProfileCustomerPassword = () => {
  useUser();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const [validationErrors, setValidationErrors] = useState<any>({
    passwordError: false,
    password: '',
  });

  const goTo = (path: string, blank?: boolean) => {
    if (!blank) {
      navigate(path);
      return;
    }
    window.open(path, '_blank')?.focus();
  };

  const fetchNewPassword = async () => {
    if (user?.id)
      try {
        const clearErrors = {
          password: '',
          passwordError: false,
        };
        const response = await postData<
          components['schemas']['UserUpdatePassword']
        >(`/profile/update_password/${user?.id}`, { password: password });

        if (response && response.status === 201) {
          goTo('/profile');
        }
        if (response && response.status === 422) {
          const newObj: any = { ...clearErrors };
          response.data.errors.forEach((item: any) => {
            newObj[item.context.label] = item.message;
          });
          setValidationErrors(newObj);
        }
      } catch (e) {
        console.log('fetchNewPassword', e);
      }
  };

  return (
    <PageLayout>
      <Divider height="70px" />
      <div className="registration__container">
        <Divider className="divider__horizontal" />
        <>
          <div className="login__content">
            <div className="form__header">
              <h1>Новый пароль</h1>
            </div>
            <div className="form">
              <div className="form__input">
                <label htmlFor="email">
                  <h5 className="mb-4 readonly">Email</h5>
                </label>
                <Input type={'text'} value={user?.email} readOnly />
              </div>
              <div className={'mb-30'}>
                <div className="form__input">
                  <label htmlFor="password">
                    <h5 className="mb-4">
                      Новый пароль<span className="dot">*</span>
                    </h5>
                  </label>
                  <Input
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    handleChange={setPassword}
                    icon={
                      <button onClick={() => setShowPassword(!showPassword)}>
                        <EyeOpenIcon />
                      </button>
                    }
                    errorText={validationErrors.password}
                    isError={validationErrors.passwordError}
                    autoComplete={'new-password'}
                    tipText={
                      'Не менее 6 знаков: латинские буквы, цифры, специальные символы'
                    }
                  />
                </div>
              </div>
              <div className="profile__button-group">
                <Button
                  onClick={() => goTo('/profile')}
                  children="Вернуться"
                  mode={'secondary-button'}
                />
                <Button
                  onClick={() => fetchNewPassword()}
                  children="Сохранить"
                />
              </div>
            </div>
          </div>
          <Divider className="divider__horizontal"></Divider>
        </>
      </div>
    </PageLayout>
  );
};

export default ProfileCustomerPassword;
