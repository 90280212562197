import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import Button from '../../components/Buttons/Button/Button';
import Divider from '../../components/Divider/Divider';
import PageLayout from '../../components/PageLayout/PageLayout';
import appDataStorage from '../../functions/appDataStorage';
import CautionIcon from '../../icons/CautionIcon';
import MailIcon from '../../icons/MainIcon';
import Instance from '../../settings/AxiosInstance';
import { setUser } from '../../store/projectSlice';

const instance = new Instance();

const RegistrationComplete = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userUid } = useParams();

  const goTo = (path: string, blank?: boolean) => {
    if (!blank) {
      navigate(path);
      return;
    }
    window.open(path, '_blank')?.focus();
  };

  const fetchRegistration = async () => {
    try {
      console.log('userUid', userUid);
      const response = await instance.post(`/registration/verify/${userUid}`);
      if (response && response.status === 201) {
        appDataStorage().setData('token', response.data.session.accessToken);
        appDataStorage().setData('username', response.data?.name);
        dispatch(setUser(response.data));
        goTo('/profile');
      }

      if (response && response.status === 400) {
        console.log('RESPONSE_ERROR', response.data.message);
      }
    } catch (e) {
      console.log('E', e);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      fetchRegistration();
    }, 2000);
  }, []);

  return (
    <PageLayout>
      <Divider height="70px" />
      <div className="registration__container-large">
        <Divider className="divider__horizontal"></Divider>
        <div className="registration__content">
          <div className="form__header">
            <h1>Завершить регистрацию</h1>
          </div>
          <div className="registration__info mb-30">
            <MailIcon className="registration__info-icon" />
            <div className="body__text-16">
              Ссылка для потверждения электронной почты <br />
              была отправлена на адрес <span>vasiliev@gmail.com</span>.<br />
              Убедитесь, что письмо не попало в папку «Спам».
            </div>
          </div>
          <div className="registration__info mb-48">
            <CautionIcon className="registration__info-icon" />
            <div className="body__text-16">
              Для завершения регистрации нажмите на ссылку <br />в полученном
              электронном письме.
            </div>
          </div>
          <Button
            onClick={() => goTo('/')}
            children="Отправить ссылку повторно"
          />
        </div>
        <Divider className="divider__horizontal"></Divider>
      </div>
    </PageLayout>
  );
};

export default RegistrationComplete;
