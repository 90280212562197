import * as React from "react";
import {useDispatch, useSelector} from 'react-redux';
import { useNavigate } from "react-router-dom";

import PopupMenuLayout from '../../components/PopupMenuLayout/popupMenuLayout';
import appDataStorage from '../../functions/appDataStorage';
import AvatarIcon from '../../icons/AvatarIcon';
import {selectUser, setUser} from '../../store/projectSlice';

const UserInfo: React.FC = () => {
  const [activeMenu, setActiveMenu] = React.useState<boolean>(false);
  const controlRef = React.useRef<HTMLButtonElement>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const toggleMenu = () => {
    setActiveMenu(!activeMenu);
  };

  const closeMenu = () => {
    setActiveMenu(false);
  };

  const onMenuClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    toggleMenu();
  };

  const logout = () => {
    appDataStorage().deleteAllData();
    dispatch(setUser(null));
    navigate('/login');
  };

  return (
    <div className="header__user-info-wrapper">
      <button
        className="header__user-info"
        ref={controlRef}
        onClick={onMenuClick}
      >
        {appDataStorage().getData('username') && (
          <div className="header__user-name">
            {appDataStorage().getData('username')}
          </div>
        )}
        <div className="header__avatar">
          <AvatarIcon width={42} height={42} />
        </div>
      </button>
      {activeMenu && (
        <PopupMenuLayout
          className="user-logout__popup-menu"
          closeMenu={closeMenu}
          controlRef={controlRef}
        >
          <button
            type="button"
            className="popup-menu-item"
            onClick={() => {
              logout();
              closeMenu();
            }}
          >
            Выйти
          </button>
        </PopupMenuLayout>
      )}
    </div>
  );
};

export default UserInfo;
