import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';

// import socketMiddleware from '../utils/socketMiddleware';
import projectSlice from './projectSlice';

const store = configureStore({
  reducer: {
    project: projectSlice,
  },
  middleware: [thunk],
});

export type RootState = ReturnType<typeof store.getState>;

export default store;
