import * as React from "react";

function CautionIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M12 2.5l-11 19h22l-11-19z"
        stroke="#5E5D67"
        strokeWidth={2}
        strokeLinejoin="round"
      />
      <path
        d="M12 17.5v.5M12 9.5l.004 5"
        stroke="#5E5D67"
        strokeWidth={2}
        strokeLinecap="round"
      />
    </svg>
  );
}

export default CautionIcon;
