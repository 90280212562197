import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import {patchData, postData} from "../../actions/actions";
import Button from '../../components/Buttons/Button/Button';
import Checkbox from "../../components/Checkbox";
import Divider from '../../components/Divider/Divider';
import Input from "../../components/Inputs/Input";
import RadioButton from "../../components/RadioButton";
import SideMenu from '../../components/SideMenu/SideMenu';
import {components} from "../../generated/apiTypes";
import ArrowIcon from '../../icons/ArrowIcon';
import {selectUser, setUser} from '../../store/projectSlice';

interface IProfileCustomerEditProps {
  setViewMode(): void;
}

const ProfileCustomerEdit: React.FC<IProfileCustomerEditProps> = ({
  setViewMode,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const [name, setName] = React.useState<string | null>();
  const [patronymic, setPatronymic] = React.useState<string | null>();
  const [surname, setSurname] = React.useState<string | null>();
  const [checkBox1, setCheckbox1] = React.useState<boolean>(false);
  const [checkBox2, setCheckbox2] = React.useState<boolean>(false);
  const [checkBox3, setCheckbox3] = React.useState<boolean>(false);
  const [gender, setGender] = React.useState<'MALE' | 'FEMALE' | 'NOT_CHOSEN'>(
    'NOT_CHOSEN',
  );
  const [validationErrors, setValidationErrors] = useState<any>({
    name: '',
    patronymic: '',
    surname: '',
  });

  React.useEffect(() => {
    setName(user?.name);
    setPatronymic(user?.patronymic);
    setSurname(user?.surname);
  }, [user]);

  const goTo = (path: string, blank?: boolean) => {
    if (!blank) {
      navigate(path);
      return;
    }
    window.open(path, '_blank')?.focus();
  };

  const fetchSaveFields = async () => {
    try {
      const clearErrors = {
        name: '',
        patronymic: '',
        surname: '',
      };
      const response = await patchData<components['schemas']['UserUpdate']>(
        '/profile',
        {
          name: name && name !== user?.name ? name : undefined,
          patronymic:
            patronymic && patronymic !== user?.patronymic
              ? patronymic
              : undefined,
          surname: surname && surname !== user?.surname ? surname : undefined,
        },
      );

      if (response && response.status === 201) {
        dispatch(setUser(response.data));
        setViewMode();
      }
      if (response && response.status === 422) {
        const newObj: any = { ...clearErrors };
        response.data.errors.forEach((item: any) => {
          newObj[item.context.label] = item.message;
        });
        setValidationErrors(newObj);
      }
    } catch (e) {
      console.log('fetchSaveFields', e);
    }
  };

  return (
    <>
      <div className="profile__container">
        <div className="profile__content">
          <SideMenu />
          <div className="profile__right-wrapper">
            <div className="profile__right-content">
              <div className="profile__navigation-links">
                <Link
                  to={'/profile'}
                  state={{ edit: false }}
                  className="body__link"
                >
                  Профиль
                </Link>
                <ArrowIcon className="arrow-icon" />
                <span className="body__text-16">Редактировать профиль</span>
              </div>
              <div className="profile__right-title mb-60">
                <h1>Редактировать профиль</h1>
              </div>
              <div className="form__input">
                <label htmlFor="name">
                  <h5 className="mb-4">
                    Имя<span className="dot">*</span>
                  </h5>
                </label>
                <Input
                  type="text"
                  value={name || undefined}
                  handleChange={setName}
                  errorText={validationErrors.name}
                />
              </div>
              <div className="form__input">
                <label htmlFor="name">
                  <h5 className="mb-4">Отчество</h5>
                </label>
                <Input
                  type="text"
                  value={patronymic || undefined}
                  handleChange={setPatronymic}
                  errorText={validationErrors.patronymic}
                />
              </div>
              <div className="form__input">
                <label htmlFor="name">
                  <h5 className="mb-4">Фамилия</h5>
                </label>
                <Input
                  type="text"
                  value={surname || undefined}
                  handleChange={setSurname}
                  errorText={validationErrors.surname}
                />
              </div>
              <div className="form__input">
                <label htmlFor="email">
                  <h5 className="mb-4 readonly">Email</h5>
                </label>
                <Input type="email" readOnly value={user?.email} />
              </div>
              <div className="form__input">
                <label htmlFor="phone">
                  <h5 className="mb-4">Телефон</h5>
                </label>
                <input
                  id="phone"
                  className="form__input-item mb-30"
                  type="tel"
                />
              </div>
              <div className="form__input profile__checkboxes">
                <Checkbox
                  label="Доверяю только исполнителям со строительным образованием"
                  checkedValue={checkBox1}
                  setCheckedValue={setCheckbox1}
                />
                <Checkbox
                  label="Для меня важно наличие гарантии на работы"
                  checkedValue={checkBox2}
                  setCheckedValue={setCheckbox2}
                />
                <Checkbox
                  label="Для меня важно заключение договора на работы/услуги"
                  checkedValue={checkBox3}
                  setCheckedValue={setCheckbox3}
                />
              </div>
              <>
                <label htmlFor="phone">
                  <h5 className="mb-18">Пол</h5>
                </label>
                <div className="form__input profile__checkboxes">
                  <RadioButton
                    label={'не выбран'}
                    value={'NOT_CHOSEN'}
                    setValue={value =>
                      setGender(value as 'MALE' | 'FEMALE' | 'NOT_CHOSEN')
                    }
                    checked={gender === 'NOT_CHOSEN'}
                  />
                  <RadioButton
                    label={'жен'}
                    setValue={value =>
                      setGender(value as 'MALE' | 'FEMALE' | 'NOT_CHOSEN')
                    }
                    value={'FEMALE'}
                    checked={gender === 'FEMALE'}
                  />
                  <RadioButton
                    label={'муж'}
                    setValue={value =>
                      setGender(value as 'MALE' | 'FEMALE' | 'NOT_CHOSEN')
                    }
                    value={'MALE'}
                    checked={gender === 'MALE'}
                  />
                </div>
              </>
              <div className="mb-60">
                <Button
                  onClick={() => goTo('/profile/newpassword')}
                  children="Сменить пароль"
                />
              </div>
              <Button onClick={()=>fetchSaveFields()} children="Сохранить" />
            </div>
            <Divider className="divider__horizontal" />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileCustomerEdit;
