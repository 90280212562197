import React, { FC, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Button from '../../components/Buttons/Button/Button';
import Divider from '../../components/Divider/Divider';
import PageLayout from '../../components/PageLayout/PageLayout';
import CautionIcon from '../../icons/CautionIcon';
import MailIcon from '../../icons/MainIcon';
import Instance from '../../settings/AxiosInstance';
import { RootState } from '../../store/configureStore';
import { IProjectSlice } from '../../store/types';

const instance = new Instance();

interface RecoverProps {
  user: IProjectSlice['user'];
}

const RegistrationConfirm: FC<RecoverProps> = ({ user }) => {
  const navigate = useNavigate();

  const [confirmComplete, setConfirmComplete] = useState<boolean>(false);

  const fetchConfirm = async () => {
    try {
      const response = await instance.post('/registration/confirm', {
        email: user?.email,
      });

      if (response && response.status === 201) {
        setConfirmComplete(true);
      }

      if (response && response.status === 400) {
        console.log('Confrim Error');
      }
    } catch (e) {}
  };

  return (
    <PageLayout>
      <Divider height="70px" />
      <div className="registration__container-large">
        <Divider className="divider__horizontal"></Divider>
        <div className="registration__content">
          <div className="form__header">
            <h1>Завершить регистрацию</h1>
          </div>
          <div className="registration__info mb-30">
            <MailIcon className="registration__info-icon" />
            <div className="body__text-16">
              Ссылка для потверждения электронной почты <br />
              была отправлена на адрес <span>{user?.email}</span>.<br />
              Убедитесь, что письмо не попало в папку «Спам».
            </div>
          </div>
          <div className="registration__info mb-48">
            <CautionIcon className="registration__info-icon" />
            <div className="body__text-16">
              Для завершения регистрации нажмите на ссылку <br />в полученном
              электронном письме.
            </div>
          </div>
          {confirmComplete ? (
            <div className="registration__info mb-48">
              {/* <CautionIcon className="registration__info-icon" /> */}
              <div className="body__text-16">
                Письмо с подтверждением было повторно отправлено на почту&nbsp;
                {user?.email}
              </div>
            </div>
          ) : (
            <Button
              onClick={() => fetchConfirm()}
              children="Отправить ссылку повторно"
            />
          )}
        </div>
        <Divider className="divider__horizontal"></Divider>
      </div>
    </PageLayout>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: state.project.user,
});

export default connect(mapStateToProps)(RegistrationConfirm);
