function appDataStorage() {
  const getData = (key?: string) => {
    let obj: any = localStorage.getItem('remontprosto');
    if (!obj) return false;
    obj = JSON.parse(obj);
    if (key) return obj[key];
    return obj;
  };

  const setData = (key: string, value: any) => {
    let obj: any = localStorage.getItem('remontprosto');
    if (!obj) {
      obj = {};
    } else {
      obj = JSON.parse(obj);
    }
    obj[key] = value;
    obj = JSON.stringify(obj);
    localStorage.setItem('remontprosto', obj);
  };

  const deleteData = (key: string) => {
    let obj: any = localStorage.getItem('remontprosto');
    if (!obj) return false;
    obj = JSON.parse(obj);
    if (!obj[key]) return false;
    delete obj[key];
    obj = JSON.stringify(obj);
    localStorage.setItem('remontprosto', obj);
  };

  const deleteAllData = () => {
    localStorage.removeItem('remontprosto');
  };

  return {
    getData,
    setData,
    deleteData,
    deleteAllData,
  };
}

export default appDataStorage;
