import * as React from "react";

function HeartIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={22}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.5 1A5.5 5.5 0 001 6.5C1 12 7.5 17 11 18.163 14.5 17 21 12 21 6.5a5.5 5.5 0 00-10-3.163A5.494 5.494 0 006.5 1z"
        stroke="#5E5D67"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default HeartIcon;
