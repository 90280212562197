import React, { ComponentProps, FC } from 'react';

import GoogleIcon from '../../../icons/GoogleIcon';

type ButtonProps = ComponentProps<'button'>;

const GoogleButton: FC<ButtonProps> = ({ ...props }) => {
  return (
    <button className="button__google-container" {...props}>
      <div className="button__google-icon">
        <GoogleIcon />
      </div>
      <p className="button__google-text">Войти через Google</p>
    </button>
  );
};

export default GoogleButton;
