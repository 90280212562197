import React, { ComponentProps, FC, ReactNode } from 'react';

import classnames from 'classnames';

import Divider from '../Divider/Divider';

import './inputStyle.scss';

interface InputProps extends ComponentProps<'input'> {
  icon?: ReactNode;
  isError?: boolean;
  errorText?: string;
  tipText?: string;
  handleChange?: (e: string) => void;
}

const Input: FC<InputProps> = ({
  icon,
  errorText,
  isError,
  handleChange,
  tipText,
  ...props
}) => {
  const [isFocus, setIsFocus] = React.useState<boolean>();

  const onFocusHandler = e => {
    setIsFocus(true);
    if (props.onFocus) props.onFocus(e);
  };

  const onBlurHandler = e => {
    setIsFocus(false);
    if (props.onBlur) props.onBlur(e);
  };

  return (
    <div className={classnames('input__container')}>
      <div
        className={classnames(
          'input__content',
          (errorText || isError) && 'input-error',
          isFocus && 'focused',
        )}
      >
        <input
          {...props}
          value={props.value}
          onChange={
            handleChange ? e => handleChange(e.target.value) : undefined
          }
          onBlur={onBlurHandler}
          onFocus={onFocusHandler}
        />
        {icon && icon}
      </div>
      {errorText ? (
        <p className="input__text-error">{errorText}</p>
      ) : tipText ? (
        <p className="body__tag">{tipText}</p>
      ) : (
        <Divider height="16px" />
      )}
    </div>
  );
};

export default Input;
