import {useNavigate} from "react-router-dom";

import axios, { AxiosError, AxiosResponse } from 'axios';
import { AxiosInstance } from 'axios';

import appDataStorage from '../functions/appDataStorage';
import store from '../store/configureStore';
import { handleErrors } from '../store/projectSlice';
import url from './url';

class Instance {
  instance: AxiosInstance;

  requestTime: any;

  responseTime: any;

  abortController: { key: string; controller: AbortController }[];

  constructor() {
    const instance = axios.create({
      baseURL: url,
    });

    instance.defaults.headers.get.Accept = 'application/json';
    instance.defaults.headers.post.Accept = 'application/json';
    instance.defaults.headers.post['Content-Type'] = 'application/json';

    instance.defaults.timeout = 1000 * 60;

    instance.interceptors.request.use(this.handleRequest, undefined);
    instance.interceptors.response.use(this.handleResponse, this.handleResponseError);

    this.requestTime = null;
    this.responseTime = null;
    this.instance = instance;
    this.abortController = [];
  }

  handleRequest = async (request: any) => {
    try {
      const abortController = new AbortController();
      const storageData = await appDataStorage().getData();
      if (storageData.token) request.headers.Authorization = 'Bearer ' + storageData.token;
      this.requestTime = new Date();
      request.signal = abortController.signal;
      this.abortController.push({
        key: request.url,
        controller: abortController,
      });
      console.log('REQUEST', {
        method: request.method,
        url: request.url,
        data: request.data,
      });
      return request;
    } catch (e) {
      console.log('INSTANCE_ERR', e);
    }
  };

  handleResponse = (res: AxiosResponse) => {
    const resTime = new Date();
    this.responseTime = (resTime.getTime() - this.requestTime.getTime()) / 1000;
    if (res.config.url) {
      this.abortController = this.abortController.filter((item) => item.key !== res.config.url);
    }
    console.log('RESPONSE', {
      responseTime: this.responseTime,
      status: res.status,
      method: res?.config.method,
      url: res.config.url,
      data: res.data,
    });
    return res;
  };

  handleResponseError = async (err: any) => {
    console.log('NETWORK_ERR', err.response);
    if (err && err.isAxiosError) {
      const { status, data, config } = err.response as any;
      if (status === 400) {
        return err.response;
      } else if (status === 422) {
        return err.response;
      } else if (status === 401) {
        appDataStorage().deleteData('token');
        if (err?.response) store.dispatch(handleErrors(err?.response));
        location.replace('/login');
      } else if (status === 403) {
        //
      } else if (status === 500) {
        console.log('RESPONSE', err);
        store.dispatch(handleErrors({ status, message: 'Server Error' }));
      }
    } else {
      return err.response;
    }
  };

  get = async (path: string, params?: any) => {
    try {
      const response = await this.instance.get(path, params ? params : {});
      return response;
    } catch (e) {
      console.log('GET_ERR', e);
    }
  };

  post = async (path: string, data?: any, type?: string): Promise<AxiosResponse | undefined> => {
    let response;
    try {
      if (type) {
        response = await this.instance.post(path, data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      } else {
        response = await this.instance.post(path, data);
      }
      return response;
    } catch (e) {
      console.log('POST_ERR', e);
    }
  };

  patch = async (path: string, data: any, type?: string) => {
    let response;
    try {
      if (type) {
        response = await this.instance.patch(path, data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      } else {
        response = await this.instance.patch(path, data);
      }

      return response;
    } catch (e) {
      console.log('PATCH_ERR', e);
    }
  };

  put = async (path: string, data?: any, type?: string) => {
    let response;
    try {
      if (type) {
        response = await this.instance.put(path, data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      } else {
        response = await this.instance.put(path, data);
      }
      return response;
    } catch (e) {
      console.log('PUT_ERR', e);
    }
  };

  delete = async (path: any) => {
    try {
      const response = await this.instance.delete(path);
      return response;
    } catch (e) {
      console.log('DELETE_ERR', e);
    }
  };

  abort = (abortUrl: string) => {
    this.abortController = this.abortController
      .map((item) => {
        if (item.key === abortUrl) {
          item.controller.abort();
        }
        return item;
      })
      .filter((item) => item.key !== abortUrl);
  };
}

export default Instance;
