import * as React from 'react';

function WorkerIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16 8a4 4 0 11-8 0M12 4a4 4 0 00-4 4h8a4 4 0 00-4-4zM6 8h12M12 2v2M12 13.5c-4.694 0-8.5 3.582-8.5 8h17c0-4.418-3.806-8-8.5-8zM9 17v2M15 17v2"
        stroke="#5E5D67"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default WorkerIcon;
