import * as React from "react";

function LogoIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0h24v24h-7.12v-7.392c-4.064 0-7.392 3.312-7.392 7.392H0V0z"
        fill="#8F90DD"
      />
    </svg>
  );
}

export default LogoIcon;
